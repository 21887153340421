<style lang="scss">
#printme {
  visibility: hidden;
}
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 75%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .modal-content {
    min-height: 46rem !important;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="xl"
    v-model="showModal"
    :title="'Doc No.: ' + finishedGoodsDetail.doc_no"
  >
    <div class="row" v-if="loadingData">
      <div class="col-12 text-center" style="padding: 10px">
        <loadingSpinner></loadingSpinner>
        <br />
        <h6>Please Wait, we are get the details.</h6>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="row">
          <div class="col-12">
            <label for="">Reported Date:</label>
            <label for="" class="detail ml-2">{{
              finishedGoodsDetail.reported_date
            }}</label>

            <button @click="print" class="float-right btn btn-info btn-sm">
              Print
            </button>
          </div>
        </div>

        <div
          class="log mt-2"
          style="font-weight: 600; min-height: 30rem !important"
        >
          <div class="row">
            <div class="col-md-12" style="background: white">
              <table class="table table-striped" id="display-table1">
                <tbody>
                  <tr>
                    <td colspan="3">
                      Product Details (as per factory standard)
                    </td>
                    <td
                      :colspan="finishedGoodsDetail.data.length"
                      style="text-align: center"
                    >
                      Observation
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">Variety:</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.biscuit_name }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">Batch No:</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.batch_no }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">Mfd:</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ formatMonth(item.manufactured) }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">Average Gross Weight / Pkt. (g):</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.average_gross_weight }}
                    </td>
                  </tr>

                  <tr>
                    <td>S.No</td>
                    <td>Parameter</td>
                    <td>Specifications</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    ></td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Moisture Content</td>
                    <td>3.5% (Max)</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.moisture_content }}
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Total (Ash Dry Basis)</td>
                    <td>3.0% (Max)</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.total_dry_basis }}
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>AIA (Dry Basis)</td>
                    <td>0.1% (Max)</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.aia_dry_basis }}
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Acidity of extracted fat (DB)</td>
                    <td>2% (Max)</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.acidity_extracted_fat }}
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Color (Top)</td>
                    <td>Golden</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.top_color }}
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Bottom</td>
                    <td>Golden brown</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.bottom_color }}
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Texture (Bite)</td>
                    <td>Crunchy</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.texture }}
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Packing of Biscuits (Sealing)</td>
                    <td>Air tight</td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.packing_of_biscuit }}
                    </td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>No. of Biscuits /Pkt</td>
                    <td></td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.no_of_biscuits_per_packt }}
                    </td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>Biscuit Cell</td>
                    <td></td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.biscuit_cell }}
                    </td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>Cream Weight</td>
                    <td></td>
                    <td
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.cream_weight }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" id="printme">
      <div class="col-md-12">
        <div class="log">
          <div class="row">
            <div class="col-md-12">
              <table
                class="table table-striped"
                style="
                  margin-top: 5px;
                  border: 1px solid black;
                  border-collapse: collapse;
                  width: 1400px;
                "
              >
                <thead>
                  <tr>
                    <th
                      style="
                        width: 30%;
                        padding: 10px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: center;
                      "
                      scope="col"
                    >
                      <img
                        width="225"
                        height="100"
                        alt="Logo"
                        src="@/assets/images/logo/pashupati_biscuits.png"
                        class="img-logo"
                      />
                    </th>
                    <th
                      style="
                        width: 40%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 10px;
                        text-align: center;
                        font-size: 24px;
                      "
                      scope="col"
                    >
                      <span
                        >Quality Control Section<br />
                        <hr />
                        Finished Goods Analysis Report
                      </span>
                    </th>
                    <th
                      style="
                        width: 30%;
                        padding: 10px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: center;
                        font-size: 24px;
                      "
                      scope="col"
                    >
                      Doc. No : {{ finishedGoodsDetail.doc_no }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div>
          <h3 style="text-align: center; text-decoration: underline">
            Certificate of Analysis
          </h3>
          <h4 style="text-align: right">
            Reported Date: {{ finishedGoodsDetail.reported_date }}
          </h4>
        </div>
        <div class="log">
          <div class="row">
            <div class="col-md-12">
              <table
                class="table table-striped"
                id="display-table"
                style="
                  border: 1px solid black;
                  border-collapse: collapse;
                  width: 1400px;
                  margin-top: 1rem;
                  font-size: 18px;
                "
              >
                <thead>
                  <tr>
                    <th
                      colspan="3"
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        font-size: 20px;
                      "
                    >
                      Product Details (as per factory standard)
                    </th>
                    <th
                      :colspan="finishedGoodsDetail.data.length"
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: center;
                        font-size: 20px;
                      "
                    >
                      Observation
                    </th>
                  </tr>
                  <tr>
                    <th
                      colspan="3"
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: right;
                      "
                    >
                      Variety:
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.biscuit_name }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: right;
                      "
                      colspan="3"
                    >
                      Batch No:
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.batch_no }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: right;
                      "
                      colspan="3"
                    >
                      Mfd:
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ formatMonth(item.manufactured) }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: right;
                      "
                      colspan="3"
                    >
                      Average Gross Weight / Pkt. (g):
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.average_gross_weight }}
                    </th>
                  </tr>

                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      S.No
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Parameter
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Specifications
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    ></th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      1
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Moisture Content
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      3.5% (Max)
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.moisture_content }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      2
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Total (Ash Dry Basis)
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      3.0% (Max)
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.total_dry_basis }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      3
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      AIA (Dry Basis)
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      0.1% (Max)
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.aia_dry_basis }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      4
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Acidity of extracted fat (DB)
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      2% (Max)
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.acidity_extracted_fat }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      5
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Color (Top)
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Golden
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.top_color }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      6
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Bottom
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Golden brown
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.bottom_color }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      7
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Texture (Bite)
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Crunchy
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.texture }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      8
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Packing of Biscuits (Sealing)
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Air tight
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.packing_of_biscuit }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      9
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      No. of Biscuits /Pkt
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    ></th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.no_of_biscuits_per_packt }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      10
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Biscuit Cell
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    ></th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.biscuit_cell }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      11
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    >
                      Cream Weight
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                    ></th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                      "
                      v-for="(item, index) in finishedGoodsDetail.data"
                      :key="index"
                    >
                      {{ item.cream_weight }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="log">
          <div class="row">
            <div class="col-md-12">
              <table
                style="
                  margin-top: 2rem;
                  border: 1px solid black;
                  border-collapse: collapse;
                  width: 1400px;
                  font-size: 20px;
                "
              >
                <thead>
                  <tr>
                    <th
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        width: 50%;
                        height: 3.5rem;
                      "
                      scope="col"
                    ></th>
                    <th
                      style="
                        padding: 7.5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        width: 50%;
                        height: 3.5rem;
                      "
                      scope="col"
                    ></th>
                  </tr>
                  <tr>
                    <th
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        width: 50%;
                      "
                      scope="col"
                    >
                      Analyst
                    </th>
                    <th
                      style="
                        padding: 7.5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        width: 50%;
                      "
                      scope="col"
                    >
                      Approved by:
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!hasData && !loadingData">
      <div class="col-md-12 col-12" style="padding: 10px">
        <h5>Sorry, we didn't found details</h5>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import loadingSpinner from "@/components/frontend/spinner.vue";

import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
    hasData: {
      type: Boolean,
      default: false,
    },
    indexNum: {
      type: Number,
      default: -1,
    },
    finishedGoodsDetail: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },

  components: {
    loadingSpinner,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode == 27) {
        this.$emit("closemodal");
      }
    });
  },
  methods: {
    close() {
      this.$emit("closemodal");
    },
    formatMonth(value) {
      if (value != "") {
        var val = moment(value).format("MMMM, YYYY");
        if (val == "Invalid date") {
          return "";
        } else {
          return val;
        }
      } else {
        return "";
      }
    },

    print() {
      var contents = document.getElementById("printme").innerHTML;
      var frame1 = document.createElement("iframe");
      frame1.name = "frame1";
      frame1.style.position = "absolute";
      frame1.style.top = "-1000000px";
      document.body.appendChild(frame1);
      var frameDoc = frame1.contentWindow
        ? frame1.contentWindow
        : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;
      frameDoc.document.open();
      frameDoc.document.write(`<html><head>`);
      frameDoc.document.write("</head><body>");
      frameDoc.document.write(contents);
      frameDoc.document.write("</body></html>");
      frameDoc.document.close();
      var css = "@page { size: A4 landscape; }",
        head =
          frameDoc.document.head || document.getElementsByTagName("head")[0],
        style = frameDoc.document.createElement("style");

      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(frameDoc.document.createTextNode(css));
      }

      head.appendChild(style);
      setTimeout(function () {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        document.body.removeChild(frame1);
      }, 500);
      return false;
    },
  },

  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function (value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      return moment(String(fullDate)).format("h:mm a");
    },
    sDateFormate: function (date) {
      if (date == null) {
        return;
      }
      return moment(String(date)).format("dddd, MMMM DD, YYYY");
    },
  },
};
</script>
